import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;
const { VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async getPlans() {
    try {
      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/gallagher?sponsor=${VUE_APP_SPONSOR}`);
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, pet, plan) {
    person.region = person.region.id;
    person.commune = person.commune.id;
    pet.sex = pet.sex.id;
    pet.species = pet.species.id;
    const jsonData = { person, pet, planId: plan._id };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }
}
